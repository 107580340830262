"use client";
import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '@/actions/authActions';
import { setCredentials, setCurrentUser, } from '@/reducers/AuthReducers';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loginMutation] = useLoginMutation();
    const dispatch = useDispatch();
    const router = useRouter()

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    // Handle Google login
    const handleLoginAuth = (e) => {
        e.preventDefault(); // Prevent form submission
        window.open(`${process.env.NEXT_PUBLIC_BACKEND_API}/v1/auth/linkedin`, '_self'); // Redirect to Google login
    };

    // Handle form login
    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent form from refreshing the page
        setLoading(true);
        try {
            const credentials = { email, password };
            const response = await loginMutation(credentials);

            if (response.error) {
                if (response.error.status === 404) {
                    toast.error(response.error.data.error);
                } else if (response.error.status === 401) {
                    toast.error(response.error.data.error);
                } else {
                    toast.error("Failed! Please Try Again");
                }
            } else {
                dispatch(setCredentials(response.data));
                dispatch(setCurrentUser(response.data.user));

                const firstRole = response.data.roles[0];

                if (firstRole === 'Freelancer') {
                    router.push('/freelancer/jobs');
                } else if (firstRole === 'Client') {
                    router.push('/client/dashboard');
                }
                toast.success('Logged In Successfully');
            }
        } catch (error) {
            console.error('Error during login:', error);
            toast.error('Internal Server Error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="font-[sans-serif]">
                <div className="grid lg:grid-cols-1 items-center gap-4 h-screen">
                    <div className="w-full py-12 px-6 md:py-6 md:px-6 mx-auto">
                        <form autoComplete="off" onSubmit={handleLogin} className="max-w-md border p-4 rounded-lg mx-auto">
                            <div className="mb-8">
                                <h3 className="text-gray-800  font-semibold">Login iWork</h3>
                                <p className="text-sm mt-4 text-gray-800">
                                    Don't have an account{" "}
                                    <Link href="/register" className="text-flamingo-600 font-semibold hover:underline ml-1 whitespace-nowrap">
                                        Register here
                                    </Link>
                                </p>
                            </div>

                            <div>
                                <label className="text-gray-800 text-[15px] mb-2 block">Email</label>
                                <div className="relative flex items-center">
                                    <input
                                        name="email"
                                        type="text"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="new-email"
                                        className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-flamingo-600"
                                        placeholder="Enter email"
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <label className="text-gray-800 text-[15px] mb-2 block">Password</label>
                                <div className="relative flex items-center">
                                    <input
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="new-password"
                                        className="w-full text-sm text-gray-800 bg-gray-100 focus:bg-transparent px-4 py-3.5 rounded-md outline-flamingo-600"
                                        placeholder="Enter password"
                                    />
                                    <span onClick={togglePasswordVisibility} className="absolute right-2 cursor-pointer">
                                        {showPassword ? (
                                            <AiOutlineEyeInvisible className="w-[18px] h-[18px] text-gray-400" />
                                        ) : (
                                            <AiOutlineEye className="w-[18px] h-[18px] text-gray-400" />
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-wrap items-center justify-between gap-4 mt-4">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 shrink-0 text-flamingo-600 focus:ring-flamingo-500 border-gray-300 rounded-md"
                                    />
                                    <label htmlFor="remember-me" className="ml-3 block text-sm">
                                        Remember me
                                    </label>
                                </div>
                                <div>
                                    <Link href="/forget-password" className="text-flamingo-600 font-semibold text-sm hover:underline">
                                        Forgot Password?
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-8">
                                <button
                                    type="submit"
                                    className="w-full py-3 px-6 text-sm tracking-wide rounded-md text-white bg-flamingo-600 hover:bg-flamingo-700 focus:outline-none"
                                >
                                    {loading ? (
                                        <div className="flex space-x-1 items-center justify-center">
                                            <span>Signing in</span>
                                            <svg
                                                aria-hidden="true"
                                                role="status"
                                                className="inline w-4 h-4 me-3 text-gray-200 animate-spin duck:text-gray-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2734 17.9647 79.3236 21.5542 82.572 25.8294C84.9175 29.0825 86.7208 32.6685 87.9236 36.4659C88.681 38.8076 91.5422 39.6781 93.9676 39.0409Z"
                                                    fill="#1C64F2"
                                                />
                                            </svg>
                                        </div>
                                    ) : (
                                        <span>Sign in</span>
                                    )}
                                </button>
                            </div>

                            <div className="my-4 flex items-center gap-4">
                                <hr className="w-full border-gray-300" />
                                <p className="text-sm text-gray-800 text-center">or</p>
                                <hr className="w-full border-gray-300" />
                            </div>

                            {/* <button
                                type="button"
                                onClick={handleLoginAuth}
                                className="w-full flex items-center justify-center gap-4 py-3 px-6 text-sm tracking-wide text-gray-800 border border-gray-300 rounded-md bg-gray-50 hover:bg-gray-100 focus:outline-none"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" className="inline" viewBox="0 0 512 512">
                                    <path
                                        fill="#fbbd00"
                                        d="M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z"
                                    />
                                    <path
                                        fill="#0f9d58"
                                        d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z"
                                    />
                                    <path
                                        fill="#31aa52"
                                        d="m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z"
                                    />
                                    <path
                                        fill="#3c79e6"
                                        d="M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z"
                                    />
                                    <path
                                        fill="#cf2d48"
                                        d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z"
                                    />
                                    <path
                                        fill="#eb4132"
                                        d="M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z"
                                    />
                                </svg>
                                Continue with google
                            </button> */}

                            <button
                                onClick={handleLoginAuth}
                                className="bg-blue-600 w-full  text-center hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                            >
                                <img
                                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png" // LinkedIn logo
                                    alt="LinkedIn Logo"
                                    className="w-6 h-6 mr-2"
                                />
                                Sign in with LinkedIn
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}
