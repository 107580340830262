//src/actions/authActions.js
import { apiSlice } from "../services/api";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: "/login",
                method: "POST",
                body: credentials,
            }),
        }),
        register: builder.mutation({
            query: (userData) => ({
                url: "/register",
                method: "POST",
                body: { ...userData },
            }),
        }),
        getUserProfile: builder.query({
            query: () => ({
                url: "/users-profile",
                method: "GET",
            }),
        }),

        sendOtp: builder.mutation({
            query: (email) => ({
                url: "/user/otp",
                method: "PUT",
                body: { ...email }
            })
        }),

        verifyOtp: builder.mutation({
            query: (data) => ({
                url: "verify-otp",
                method: "PUT",
                body: { ...data }
            })
        }),


        updatePassword: builder.mutation({
            query: (data) => ({
                url: "update-password",
                method: "PUT",
                body: data 
            })
        }),


        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/trash/users/${id}`,
                method: "PUT",
            }),
        }),
        updateUser: builder.mutation({
            query: ({ id, ...updateUser }) => ({
                url: `/users/${id}`,
                method: "put",
                body: updateUser,
            }),
        }),
        sendEmail: builder.mutation({
            query: (email) => ({
                url: `/user/resetpassord`,
                method: "put",
                body: email,
            }),
        }),
        
        getUser: builder.mutation({
            query: () => ({
                url: "/users",
                method: "get",
            }),
        }),
        getRefreshToken: builder.mutation({
            query: () => ({
                url: "/refresh",
                method: "get",
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: "/logout",
                method: "DELETE",
            }),
        }),


    }),
});

export const {
    useLoginMutation,
    useGetUserMutation,
    useRegisterMutation,
    useLogoutMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useSendOtpMutation,
    useVerifyOtpMutation,
    useGetRefreshTokenMutation,
    useUpdatePasswordMutation,
    useGetUserProfileQuery,
    useSendEmailMutation,
} = authApiSlice;